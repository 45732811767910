import React from "react";
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import { HomePage } from "./Home";
import { CreatorPage } from "./Creator";
import { SurveyPage } from "./Survey";
import client from './graphql-init';

import "bootstrap/dist/css/bootstrap.css";

export default function SurveyJSReactApplication() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/creator/:id" component={CreatorPage}/>
          </Switch>
        </div>
      </Router>
    </ApolloProvider>
  );
}
