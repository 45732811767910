import React from "react";
import SurveyCreator from "./SurveyCreator";

export function CreatorPage(props) {
    return (
      <div>
        <SurveyCreator {...props}/>
      </div>
    );
  }
