import * as Cookies from 'js-cookie';

import { ApolloClient, ApolloLink, HttpLink, from } from '@apollo/client';

import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: window._jsenv.REACT_APP_SURVEY_GRAPHQL_URL
  // fetch: customFetch,
});

const redirectToLogIn = () => {
  Cookies.remove('token');
  Cookies.remove('user');
  localStorage.clear();
  window.location = '/auth/signin';
};

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token');
    const ssoToken = localStorage.getItem('ssotoken');
    return {
      headers: {
        ...headers,
        //Authorization: token ? `Bearer ${token}` : '',
        SsoToken: ssoToken ? `${ssoToken}` : '',
      },
    };
  });
  return forward(operation).map(response => {
    const { errors } = response;
    let unAuth = errors?.filter(
      error => error.message === 'Unauthorized error',
    );
    // TODO: check token usage
    // if (unAuth?.length >= 1) redirectToLogIn();
    return response;
  });
});

const activityMiddleware = new ApolloLink((operation, forward) => {
  // add the recent-activity custom header to the headers
  var refresh_token = localStorage.getItem('refreshToken');
  var address = localStorage.getItem('accessTokenUri');
  var data =
    'grant_type=refresh_token&refresh_token=' +
    refresh_token +
    '&client_id=' +
    localStorage.getItem('clientId');

  if (
    Date.now() >= Number(localStorage.getItem('expires_in')) &&
    refresh_token &&
    address
  ) {
    refreshToken(address, data);
  }

  return forward(operation);
});

const client = new ApolloClient({
  cache,
  link: from([authLink, activityMiddleware, httpLink]),
});

export default client;
function refreshToken(address, data) {
  fetch(address, {
    method: 'POST',
    body: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).then(refresh_token_repsonse => {
    console.log('refresh_token_repsonse', refresh_token_repsonse);
    if (refresh_token_repsonse.ok) {
      return refresh_token_repsonse.json().then(refreshJSON => {
        console.log('refreshJSON', refreshJSON);
        localStorage.setItem('token', refreshJSON.access_token);
        localStorage.setItem('refreshToken', refreshJSON.refresh_token);
        const t = new Date();
        t.setSeconds(t.getSeconds() + refreshJSON.expires_in);
        localStorage.setItem('expires_in', Date.parse(t));
        return refreshJSON.access_token;
      });
    } else {
      redirectToLogIn();
    }
  });
}
