import React, { Component } from "react";
import * as SurveyKo from "survey-knockout";
import * as SurveyJSCreator from "survey-creator";
import "survey-creator/survey-creator.css";

import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";

import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";

import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";
import axios from 'axios';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

//import "icheck/skins/square/blue.css";
import "pretty-checkbox/dist/pretty-checkbox.css";

import * as widgets from "surveyjs-widgets";

SurveyJSCreator.StylesManager.applyTheme("default");

//widgets.icheck(SurveyKo, $);
widgets.prettycheckbox(SurveyKo);
widgets.select2(SurveyKo, $);
widgets.inputmask(SurveyKo);
widgets.jquerybarrating(SurveyKo, $);
widgets.jqueryuidatepicker(SurveyKo, $);
widgets.nouislider(SurveyKo);
widgets.select2tagbox(SurveyKo, $);
//widgets.signaturepad(SurveyKo);
widgets.sortablejs(SurveyKo);
widgets.ckeditor(SurveyKo);
widgets.autocomplete(SurveyKo, $);
widgets.bootstrapslider(SurveyKo);

export const GetSurveys = gql`
  query surveys {
    surveys {
      title
    }
  }
`;

export const GetSurvey = gql`
  query survey($id: ID!) {
    survey(id: $id) {
      id
      jsonConfig
    }
  }
`;

export const UpdateSurvey = gql`
  mutation updateSurvey(
    $id: ID!,
    $jsonConfig: JSON!
  ) {
    updateSurvey(
      input: {
        id: $id
        details: {
          jsonConfig: $jsonConfig
        }
      }
  ) {
      survey {
        id
        jsonConfig
      }
    }
  }
`;

function SurveyCreator(props) {
  var surveyCreator;
  const [updateSurvey, { data: result, loading2, error2 }] = useMutation(UpdateSurvey);

  const saveMySurvey = () => {
    const params = { variables: { id: props.match.params.id, jsonConfig: surveyCreator.text } };
    updateSurvey(params);
  };

  let options = { showEmbededSurveyTab: false };
  surveyCreator = new SurveyJSCreator.SurveyCreator(null, options);
  surveyCreator.saveSurveyFunc = saveMySurvey;

  const {  data, loading, error, refetch } = useQuery(GetSurvey, {
    variables: { id: props.match.params.id },
  });

  if (loading)
    surveyCreator.text = '';
  else {
    const json = data.survey.jsonConfig;
    surveyCreator.text = json;
    surveyCreator.render("surveyCreatorContainer");
  }

  return (<div>
    <script type="text/html" id="custom-tab-survey-templates">
      {`<div id="test"></div>`}
    </script>

    <div id="surveyCreatorContainer" />
  </div>);
}

export default SurveyCreator;
