import React from "react";
import logo from "./logo.svg";
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GetSurveys = gql`
  query surveys {
    surveys {
      id
      title
    }
  }
`;


const Link = (props) => {
  const {  data, loading, error, refetch } = useQuery(GetSurveys);

  //console.log("survey id   -> " + props.match.params.id);
  //console.log("survey data -> " + JSON.stringify(data.surveys));

  //const surveys = JSON.stringify(data.surveys)

  if (loading)
    return (
      <div/>
    )
  else {
    const listItems = data.surveys.map((item) => <a href={"creator/" + item.id}>{item.title}&nbsp;&nbsp;</a>);
    return (
      <div>
        {listItems }
      </div>
    );
  }
};


export function HomePage() {
  return (
      <div className="container">

          <div className="col-lg-9 jumbotron">
              <Link/>
          </div>
      </div>
  );
}
